import { React, useEffect, useState } from 'react';
import { io } from 'socket.io-client'
//
import OrderList from './components/OrderList'

export default function App() {
  const [orders, setOrders] = useState([])
  const [id, setId] = useState(1)
  
  const URL = process.env.REACT_APP_SERVER
  const socket = io(URL);
  console.log('URL = ' + URL)

  useEffect(() => {
    function onConnect() {
      console.log('onConnect socket', socket.id);
    }

    function onOrderEvent(order) {
      order.id = id
      order.checked = false
      console.log('onOrder', JSON.stringify(order))
      setOrders(orders => [order, ...orders])
      setId(id => id + 1)
    }
  
    socket.on('connect', onConnect)
    socket.on('order', onOrderEvent)

    return () => {
      socket.off('connect', onConnect)
      socket.off('order', onOrderEvent)
      // socket is still connected here
    }
  }, [id, socket])

  function toggleChecked(id, checked) {
    console.log('toggleCheced', id, 'checked', checked)
    const newOrders = orders.map((order) => {
      if (order.id.toString() === id.toString()) {
        order.checked = checked
      }
      return order
    })
    setOrders(newOrders)
  }

  useEffect(() => {
    console.log('new orders', JSON.stringify(orders))
  }, [orders])

  socket.connect()
  socket.emit('join', 'Orders')

  return (<OrderList orders={orders} toggleChecked={toggleChecked}/>)
}
