import React from 'react';
import '../App.css';

export default function OrderList({orders, toggleChecked}) {
  const check = ({target}) => {
    toggleChecked(target.id, target.checked)
  }

  // 2024.8.11: Put <tbody> inside the first map() and added keys to the tbody and tr elements
  return (<>
    { orders.length > 0  && <table>
    {    
      orders.map((order) => {
        return (
          <tbody key={order.id + 'TB'}>
            <tr key={order.id + 'R'} className='header-row'>
              <td style={{textAlign: 'left'}}>{'#' + order.id + ') ' + order.phone}</td>
              <td><input type="checkbox" className='checkbox' id={order.id} onChange={check}></input></td>
            </tr>
          {
            ! order.checked &&
            order.items.map((item, i) => {
              return (
                <tr key={order.id + '-' + i} className='item-row'>
                  <td style={{textAlign: 'left'}}>{item.name}</td>
                  <td style={{textAlign: 'right'}}>{item.qty}</td>
                </tr>
              )
            })   
          }
          </tbody>
        )
      })
    }
    </table> }
 </>)
}

